import { Directive, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { PikSearchInputComponent } from '@pik-ui/ng-components';
import { TypedFormControl } from '@common/interfaces';

import { TablePageServiceBase } from './table-page-service';
import { ColumnType } from './table-columns';
import { SearchType } from '../types';

const DIGIT_REGEXP = /^-?\d*\.{0,1}\d+$/;

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TablePageBase implements OnDestroy {
  @ViewChild(PikSearchInputComponent)
  readonly searchInputRef: PikSearchInputComponent;

  public searchControl: TypedFormControl<string>;
  public searchTypeControl: TypedFormControl<string>;
  public destroy$ = new Subject();

  constructor(
    protected tablePageService: TablePageServiceBase,
    protected _route: ActivatedRoute,
    public searchTypes: SearchType[] = [],
  ) {
    this._initFilter();
    this.searchControl = new FormControl(tablePageService.filter.searchValue);
    const defaultTypeOfSearch = this._getDefaultTypeOfSearch(searchTypes);

    this.searchTypeControl = new FormControl(tablePageService.filter.searchField || defaultTypeOfSearch);
    this.tablePageService.filter.searchField = this.tablePageService.filter.searchField || defaultTypeOfSearch;

    this.searchTypeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((type: string) => {
      let searchValue = this.tablePageService.filter.searchValue;

      if (!DIGIT_REGEXP.test(this.searchControl.value) && this.getSearchType(type) === ColumnType.NUMBER) {
        searchValue = '';

        this.tablePageService.filter.searchValue = '';
        this.searchControl.reset('', { emitEvent: false });

        if (this.searchInputRef) {
          this.searchInputRef.focus();
        }
      }

      this.searchTypes.forEach((item: SearchType) => (this.tablePageService.filter[item.key] = ''));

      this.tablePageService.filter.searchField = type;
      this.onSearch(searchValue, type);
    });

    this.searchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((value: string) => {
          if (tablePageService.filter.searchValue !== value) {
            this.tablePageService.filter.searchValue = value;
            this.onSearch(value, this.tablePageService.filter.searchField || defaultTypeOfSearch);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  abstract reload();

  onSearch(value: string, filterKey: string) {
    this.tablePageService.filter[filterKey] = value;
    this.tablePageService.filter.tableParams.pageIndex = 1;
    this.tablePageService.filter.filterChanges();
    this.reload();
  }

  onChangePageNumber(pageNumber: number) {
    this.tablePageService.filter.tableParams.pageIndex = pageNumber;

    this.tablePageService.filter.filterChanges();
    this.reload();
  }

  onChangePageSize(pageSize: number) {
    this.tablePageService.filter.tableParams.limit = pageSize;
    this.tablePageService.filter.tableParams.pageIndex = 1;

    this.tablePageService.filter.filterChanges();
    this.reload();
  }

  onSort(event) {
    const { active, direction } = event;
    const dir = direction === 'asc' ? 'desc' : 'asc';
    this.tablePageService.filter.sortBy = active;
    this.tablePageService.filter.sortOrder = dir;
    this.tablePageService.filter.tableParams.pageIndex = 1;

    this.tablePageService.filter.filterChanges();
    this.reload();
  }

  resetFilter() {
    this.tablePageService.filter.clear();
    this.tablePageService.filter.filterChanges();
    this.reload();
  }

  getSearchType(type: string): ColumnType {
    const item = this.searchTypes.find((s: SearchType) => type === s.key);
    return item ? item.type : ColumnType.STRING;
  }

  private _initFilter() {
    if (!this.tablePageService.filter) {
      throw new Error('Page filter not set in service');
    }
  }

  private _getDefaultTypeOfSearch(searchTypes: SearchType[]): string {
    const defaultType = searchTypes.find((x) => x.default);
    return defaultType ? defaultType.key : searchTypes[0]?.key ?? null;
  }
}
