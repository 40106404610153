import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  userImageCache = new Map<string, any>();
  requestsCache = new Map<string, HttpEvent<unknown>>();
}
