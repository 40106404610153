import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { UserImageResp } from '@common/interfaces';
import { CacheService } from '@core/services/cache.service';
import { isHrmImageUrlDisabled } from '@features/users/views/users-main/utils/user-functions';
import { UsersApiService } from '@services/api';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss'],
})
export class UserPhotoComponent {
  defaultImage = './assets/images/user.png';
  loading = false;
  @Input() set value(value: string) {
    if (this.loading || !value || isHrmImageUrlDisabled()) return;
    this.loading = true;
    this.loadUserPhoto(value).subscribe();
  }
  @Input() isBackground = false;

  photo$ = new BehaviorSubject<SafeUrl>(this.defaultImage);
  backgroundPhoto$ = new BehaviorSubject<SafeStyle>(`url(${this.defaultImage})`);
  constructor(
    private readonly _usersApi: UsersApiService,
    private cacheService: CacheService,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  loadUserPhoto(login: string, index: 1 | 2 = 1): Observable<UserImageResp> {
    const cached = this.cacheService.userImageCache.get(login + index);

    if (cached !== undefined) {
      this.photo$.next(this.domSanitizer.bypassSecurityTrustUrl(cached));
      this.backgroundPhoto$.next(this.domSanitizer.bypassSecurityTrustStyle(`url(${cached})`));
      this.loading = false;
      return of(cached);
    }

    return this._usersApi.getAvatarImage(login, index).pipe(
      tap((resp: UserImageResp) => {
        const url = URL.createObjectURL(resp.file as Blob) as string;
        this.cacheService.userImageCache.set(login + index, url);
        this.photo$.next(this.domSanitizer.bypassSecurityTrustUrl(url));
        this.backgroundPhoto$.next(this.domSanitizer.bypassSecurityTrustStyle(`url(${url})`));
        this.loading = false;
        this.cdr.detectChanges();
      }),
    );
  }
}
