import { environment } from 'environments/environment';

export const ApiPaths = {
  Attributes: 'Attributes',
  Categories: 'Categories',
  Disciplines: 'Disciplines',
  Families: 'Families',
  FamilySymbols: 'FamilySymbols',
  FamilyAttributes: 'FamilyAttributes',
  Folders: 'Folders',
  History: 'Audit',
  FunctionalTypes: 'FunctionalTypes',
  ApplicationSettings: 'ApplicationSettings',
  FunctionalTypeParameters: 'FunctionalTypeParameters',
  FunctionalTypeParameterAttributes: 'FunctionalTypeParameterAttributes',
  FunctionalTypeAttributes: 'FunctionalTypeAttributes',
  FunctionalTypeAttributeValues: 'FunctionalTypeAttributeValues',
  MaterialAttributes: 'MaterialAttributes',
  MaterialParameterValueSets: 'MaterialParameterValueSets',
  MaterialParameters: 'MaterialParameters',
  Materials: 'Materials',
  Parameters: 'Parameters',
  ParameterValues: 'ParameterValues',
  ParameterValueSets: 'ParameterValueSets',
  Projects: 'Projects',
  Requests: 'Requests',
  RequestStatues: 'RequestStatuses',
  Roles: 'Roles',
  Permissions: 'Permissions',
  DataTypes: 'DataTypes',
  UniqueParameters: 'UniqueParameters',
  Update: 'DesktopAssemblies',
  UnitTypes: 'UnitTypes',
  Users: 'Users',
  Companies: 'Companies',
  ProjectTemplates: 'ProjectTemplates',
  License: 'License',
  Versions: 'Versions',
  Diagnostics: 'Diagonstics',
  HeapFiles: 'HeapFiles',
  LoadLog: 'LoadLog',
  Logs: 'Logs',
  TreeItems: 'TreeItems',
  Standards: 'Standards',
  Dashboard: 'Dashboard',
  SnapNomenclatureKinds: 'SnapNomenclatureKinds',
  Specifications: 'Specifications',
  Nomenclatures: 'Nomenclatures',
  NomenclatureTypes: 'NomenclatureTypes',
  Bindings: 'Bindings',
  BdsFm: 'BdsFm',
  BindingGroups: 'BindingGroups',
  MatchingValues: 'MatchingValues',
  MatchingProperties: 'MatchingProperties',
  BindingFilter: 'BindingFilter',
};

export const ApiHrmImagePaths = {
  Users: 'users',
};

// make endpoints using environment path
bindEnvToPaths(ApiPaths, environment.fmEndpoint);
bindEnvToPaths(ApiHrmImagePaths, environment.hrmImageEndpoint);

function bindEnvToPaths(paths: object, env: string) {
  Object.keys(paths).forEach((key) => (paths[key] = `${env}/${paths[key]}`));
}
