<div *ngIf="loading; else photo" class="loader">
  <pik-loader></pik-loader>
</div>

<ng-template #photo>
  <ng-container *ngIf="!isBackground">
    <img class="img" [src]="photo$ | async" />
  </ng-container>
  <ng-container *ngIf="isBackground">
    <div class="background" [style.background-image]="backgroundPhoto$ | async"></div>
  </ng-container>
</ng-template>
