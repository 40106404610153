import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';

/**
 * Компонент требуется для безопасного получения картинок,
 * это достигается  через http запросы и последуещего прикрепления токена авторизации
 */
@Component({
  selector: 'app-secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss'],
})
export class SecuredImageComponent {
  public url$: Observable<SafeUrl> = new BehaviorSubject<string>(null);

  @Input() private set src(src: string) {
    this.url$ = this.httpClient.get(src, { responseType: 'blob' }).pipe(
      map((e) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e as any))),
      catchError(() => {
        return of(null);
      }),
    );
  }

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {}
}
