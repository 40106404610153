import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NAVIGATION_LIST } from '@common/consts';
import { PermissionService } from '@core/account/services';

import { environment } from '../../../../environments/environment';
import { NavigationCategory } from '@common/interfaces/Navigation';
import { TitleService } from '@core/services';
import { UniqueParametersApiService } from '@services/api';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartPageComponent implements OnInit {
  pending$ = new BehaviorSubject(false);
  categories: NavigationCategory[] = [];
  version = environment.version || '1.0.0';

  constructor(
    public permissionService: PermissionService,
    private titleService: TitleService,
    private uniqueParametersApiService: UniqueParametersApiService,
  ) {}

  ngOnInit() {
    this.setMenu();
    this.titleService.setTranslatedTitle('START_PAGE.TITLE');
  }

  setMenu() {
    this.categories = NAVIGATION_LIST.filter((c) => (c.permission ? this.permissionService.access[c.permission] : true))
      .map((c) => ({
        ...c,
        pages: c.pages.filter((p) => (p.permission ? this.permissionService.access[p.permission] : true)),
      }))
      .filter((c) => c.pages.length);
  }
}
