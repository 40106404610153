import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { IdNameDto, Language, PatchOperation, UserCreateDto, UserDto } from '@common/dto';
import { UsersFilterParams } from '@common/models/filters';
import { ApiHrmImagePaths, ApiPaths } from '@common/consts/api-paths';
import { ResultPart, UserImageResp } from '@common/interfaces';
import { DomSanitizer } from '@angular/platform-browser';
import { isHrmImageUrlDisabled } from '@features/users/views/users-main/utils/user-functions';

@Injectable({ providedIn: 'root' })
export class UsersApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  public getUserList(filter?: UsersFilterParams): Observable<ResultPart<UserDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<UserDto>>(`${ApiPaths.Users}/filter`, params);
  }

  public getUser(userQuery: string | number): Observable<UserDto> {
    return this.http.get<UserDto>(`${ApiPaths.Users}/${userQuery}`);
  }

  public patch(userQuery: string, patchData: PatchOperation[]): Observable<UserDto> {
    return this.http.patch<UserDto>(`${ApiPaths.Users}/${userQuery}`, patchData).pipe(take(1));
  }

  public createUser(data: UserCreateDto): Observable<UserDto> {
    return this.http.post<UserDto>(`${ApiPaths.Users}}`, data).pipe(take(1));
  }

  public getMe(): Observable<UserDto> {
    return this.getUser('me');
  }

  public smartSearch(searchString: string = ''): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Users}/smartSearch`, {
      params: { searchString },
    });
  }

  public getAvatarImage(login: string, renditionId: 1 | 2 = 2): Observable<UserImageResp> {
    const hrmPhoto = `${ApiHrmImagePaths.Users}/photo`;
    const fallbackAvatarPath = './assets/images/user.png';
    return this.http
      .get(isHrmImageUrlDisabled() ? fallbackAvatarPath : hrmPhoto, {
        params: {
          renditionId,
          email: login,
        },
        responseType: 'blob',
      })
      .pipe(
        take(1),
        map((data) =>
          data
            ? {
                file: data,
                styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
                url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
                resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
              }
            : null,
        ),
        catchError(() => of(null)),
      );
  }

  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Users}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public updateCulture(culture: Language): Observable<UserDto> {
    return this.http.put<UserDto>(`${ApiPaths.Users}/culture`, `"${culture}"`, {
      headers: { 'Content-type': 'application/json' },
    });
  }

  public changeStandard(id: number): Observable<any> {
    return this.http.put<IdNameDto[]>(`${ApiPaths.Users}/standard`, id);
  }

  public createGoogleSheet(filter: UsersFilterParams): Observable<string> {
    const params = filter ? filter.toParams() : {};
    return this.http.post(`${ApiPaths.Users}/sheets`, params, {
      responseType: 'text',
    });
  }

  public importSheet(sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.Users}/sheets/${sheetId}`, null);
  }

  public createExcelSheet(filter: UsersFilterParams): Observable<Blob> {
    const params = filter ? filter.toParams() : {};
    return this.http.post(`${ApiPaths.Users}/excel`, params, {
      responseType: 'blob',
    });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Users}/excel`, data);
  }

  public remove(id: number) {
    return this.http.delete<void>(`${ApiPaths.Users}/${id}`);
  }
}
