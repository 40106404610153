import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiPaths } from '@common/consts';
import { IdNameDto, NomenclatureTableDto, NomenclatureTypeDto, NomenclatureTypeUpdateDto } from '@common/dto';
import { ResultColumns, ResultPart } from '@common/interfaces';
import { NomenclatureTypesFilterParams } from '@common/models/filters';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NomenclatureTypesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает характеристики по фильтру.
   * @param filter - фильтр
   */
  public getList(filter?: NomenclatureTypesFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<NomenclatureTypeDto>>(`${ApiPaths.NomenclatureTypes}/filter`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param entityId - id
   */
  public getById(entityId: number) {
    return this.http.get<NomenclatureTypeDto>(`${ApiPaths.NomenclatureTypes}/${entityId}`);
  }

  /**
   * Возвращает IdNameDto[] по id[].
   * @param ids - Список id
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.NomenclatureTypes}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  /**
   * Добавляет новый объект
   * @param entity - новый объект
   */
  public create(entity: Omit<NomenclatureTypeUpdateDto, 'id' | 'isDeleted'>) {
    return this.http.post<NomenclatureTypeDto>(ApiPaths.NomenclatureTypes, entity);
  }

  /**
   * Обновляет объект
   * @param entity - изменённые данные
   */
  public update(entity: NomenclatureTypeUpdateDto) {
    return this.http.put<NomenclatureTypeDto>(`${ApiPaths.NomenclatureTypes}`, entity);
  }

  /**
   * Удаляет объект
   * @param entityId - id
   */
  public remove(entityId: number) {
    return this.http.delete<void>(`${ApiPaths.NomenclatureTypes}/${entityId}`);
  }

  /**
   * Возвращает номенклатуры вида
   * @param id: Идентификатор вида номенклатуры
   * @param isShowDeleted: Показывать архивные номенклатуры
   */
  public getNomenclatures(id: number, isShowDeleted = false): Observable<ResultColumns<NomenclatureTableDto>> {
    return this.http.get<ResultColumns<NomenclatureTableDto>>(`${ApiPaths.NomenclatureTypes}/${id}/nomenclatures`, {
      params: {
        isShowDeleted: isShowDeleted.toString(),
      },
    });
  }

  public createGoogleSheet(filter: NomenclatureTypesFilterParams): Observable<string> {
    const params = filter ? filter.toParams() : {};
    return this.http.post<string>(`${ApiPaths.NomenclatureTypes}/sheets`, params);
  }

  public createExcelSheet(filter: NomenclatureTypesFilterParams): Observable<Blob> {
    const params = filter ? filter.toParams() : {};
    return this.http.post(`${ApiPaths.NomenclatureTypes}/excel`, params, {
      responseType: 'blob',
    });
  }
}
