<div class="start-page-wrap">
  <div class="start-page-title">
    {{ 'START_PAGE.TITLE' | translate }}
  </div>
  <pik-card class="start-page-content">
    <ng-container *ngFor="let category of categories">
      <div class="column">
        <div class="gen-app">
          <div class="gen-app__title">
            <div class="gen-app__icon">
              <svg-icon src="./assets/icons/sections/{{ category.icon }}.svg"></svg-icon>
            </div>
            <span>{{ 'MENU.CATEGORIES.' + category.title | translate }}</span>
          </div>

          <div class="gen-app__list">
            <div *ngFor="let item of category.pages" class="gen-app__list-item">
              <a routerLink="{{ item.link }}">{{ 'MENU.ITEM.' + item.name | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <app-download-app-buttons></app-download-app-buttons>
  </pik-card>

  <div class="start-page-info">
    <div class="start-page-info__version">{{ version }}</div>

    <div class="start-page-info__description">
      {{ 'START_PAGE.FOOTER_DESCRIPTION' | translate }}
      <a href="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/home" target="_blank">{{
        'START_PAGE.FOOTER_DESCRIPTION_LINK' | translate
      }}</a>
    </div>
  </div>
</div>
